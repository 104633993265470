// import { getToken } from '@/utils/auth'

const state = {
  // token: getToken(),
  name: '',
  userInfo: {},
  permissions: [],
  selectedList: {},
  status: '',
  maxNum: '' // 最多可选
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },

  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  setSelectedList(state, selectedList) {
    const arr = [...selectedList]
    state.selectedList[`${state.status}`] = arr
  },
  setCheckedList(state, params) {
    if (params.status) {
      state.selectedList[`${params.number}`] = state.selectedList[`${params.number}`].filter(item => item.staffId !== params.data.staffId)
    } else {
      state.selectedList[`${params.number}`] = state.selectedList[`${params.number}`]
        ? [...state.selectedList[`${params.number}`], params.data] : [params.data]
    }
  },
  setStatus(state, status) {
    state.status = status
  },
  clearSelectedList(state, selectedList) {
    state.selectedList = selectedList
  },
  setMaxNum(state, maxNum) {
    state.maxNum = maxNum
  }
}

const actions = {
  // 登录
  setUserInfo({ commit }, data) {
    commit('SET_TOKEN', data.token)
    commit('SET_NAME', data.USER ? data.USER.name : '')
    commit('SET_PERMISSIONS', data.PERMISSONS)
    commit('SET_USERINFO', data.USER)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
