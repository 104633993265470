import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/collaborativeOffice/index.vue'
import SelectCopy from '@/components/SelectCopy'
import SelectPerson from '@/components/SelectPerson'
const routes = [
  {
    path: '/',
    redirect: "/daily",
    // redirect: "/collaborativeOffice",
  },
  {
    path: '/collaborativeOffice',
    name: 'home',
    component: HomeView
  },
  {
    path: '/daily',
    name: 'daily',
    component: () => import('@/views/collaborativeOffice/daily/index.vue'),
  },
  {
    path: "/daily/add",
    name: "dailyAdd",
    component: () => import('@/views/collaborativeOffice/daily/add.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/daily/detail",
    name: "dailyDetail",
    component: () => import('@/views/collaborativeOffice/daily/detail.vue'),
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/collaborativeOffice/report/index.vue'),
  },
  {
    path: "/report/add",
    name: "reportAdd",
    component: () => import('@/views/collaborativeOffice/report/add.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/report/detail",
    name: "reportDetail",
    component: () => import('@/views/collaborativeOffice/report/detail.vue'),
  },
  {
    path: '/SelectCopy',
    name: 'selectCopy',
    component: SelectCopy
  },
  {
    path: '/SelectPerson',
    name: 'selectPerson',
    component: SelectPerson
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export const asyncRoutes = []
export default router
