<template>
  <div class="page-container">
    <van-nav-bar
      left-arrow
      title="工作汇报"
      safe-area-inset-top
      :border="false"
      @click-left="gotoBack"
    />
    <div class="my-page-content">
      <div class="approval-card sale-chart">
        <!-- <div class="approval-card-title">工作</div> -->
        <van-grid :column-num="4" clickable :border="false">
          <van-grid-item
            v-for="d in projectList"
            :key="d.name"
            :icon="d.icon"
            :text="d.name"
            @click="openApply(d)"
          />
        </van-grid>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon , Grid, GridItem, NavBar } from 'vant'
export default {
  name: 'Home',
  components: {
    [Icon.name]: Icon,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [NavBar.name]: NavBar,
  },
  data() {
    return {
      projectList: [
        {
          name: '工作日报',
          type: '1',
          path: '/daily',
          icon: require('@/assets/images/co/daily.png'),
        },
        {
          name: '工作汇报',
          type: '2',
          path: '/report',
          icon: require('@/assets/images/co/report.png'),
        },
      ],
    }
  },
  mounted() {
  },
  methods: {
    // 导航栏返回
    gotoBack () {
      location.href = 'jstooc://back'
    },
    // 点击跳转
    openApply(item) {
      this.$router.push({
        path: item.path,
        query: {
          type: item.type,
          name: item.name
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.page-container {
  display: flex;
  flex-direction: column;
  touch-action: none;
  height: 100vh;
  .app-header {
    position: fixed;
  }
  .header-grid {
    position: fixed;
    top: 46px;
    z-index: 99;
    width: 100%;
    .van-grid-item__content {
      padding: 8px;
    }
  }
  .my-page-content {
    flex: 1;
    overflow-y: auto;
    .approval-card {
      background-color: #fff;
      border-radius: 8px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 343px;
      margin: 0 auto;
      margin-top: 16px;
      padding: 16px;
      .approval-card-title {
        // padding-left: 6px;
        color: #333333;
        font-size: 16px;
        line-height: 16px;
        // border-left: 3px solid #1584ff;
        text-align: left;
      }
      .van-grid {
        .van-grid-item__content {
          justify-content: flex-start;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.page-container {
  .van-grid {
    .van-grid-item__content {
      justify-content: flex-start;
    }
  }
  .header-grid {
    .van-grid-item__content {
      padding: 8px;
    }
  }
}
// .ml5 {
//   margin-left: 5px;
// }
// .mr5 {
//   margin-right: 5px;
// }
// .ml12 {
//   margin-left: 12px;
// }
// .mr12 {
//   margin-right: 12px;
// }
// .ml16 {
//   margin-left: 16px;
// }
// .mr16 {
//   margin-right: 16px;
// }
// .ml20 {
//   margin-left: 20px;
// }
// .mr20 {
//   margin-right: 20px;
// }
// .ml30 {
//   margin-left: 30px;
// }
// .mr30 {
//   margin-right: 30px;
// }
</style>