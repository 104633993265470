const getters = {
  // token: state => state.user.token,
  isLogin: state => state.user.isLogin,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  layout: state => state.app.layout,
  keepAlive: state => state.approval.keepAlive
}
export default getters
