import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
// 引入vconsole，注意线上
// import vConsole from '@/plugins/index'
// 
// 引入组件样式
import 'vant/lib/index.css';
// 引入公共样式
import './styles/index.scss';
createApp(App).use(store).use(router).mount('#app')
