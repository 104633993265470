<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <component :is="Component" v-if="!$route.meta.keepAlive"></component>
      <keep-alive :include="keepAlive">
        <component :is="Component" v-if="$route.meta.keepAlive"></component>
      </keep-alive>
    </router-view>
  </div>
</template>
<script>
import { getUserPost } from '@/api/common';
import VeiBanBridge from '@/utils/bridge';
export default {
  name: 'App',
  computed: {
    keepAlive() {
      return this.$store.getters.keepAlive;
    }
  },
  async created() {
    // let token = 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2tleSI6ImJjZmQxYzI1LWUwN2YtNDYxYS04Mjk0LThlNmUwMjJjYWQwNiIsInVzZXJfaWQiOjE2OSwidXNlcm5hbWUiOiJ6eXcxMTMxIn0.BZh92sMKo0GgmrKVBR44h-6NA2xBajBdAHGeLrGF3FHW9tKcV4ZN8L2sqbNWr2QtSAGydXK99PMXcMgVi5L-zQ'
    // localStorage.setItem('token', token)
    // let oldToken ='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbktleSI6IlgtNThiZjc4MWUxM2E1NDg3Njg4ZTEwZjA1NmQyOWE5OWQtMjU0YjY3NDRlODE4NDhhMzlkMmEzYzk4NTcxMmMxYmMiLCJleHBpcmVUaW1lc3RhbXAiOiIxNzMzNzk4NDc1ODA0IiwicGhvbmUiOiIxNTI3MTE4MDIwNCIsInJlZnJlc2hUaW1lc3RhbXAiOiIxNzE4MDMwNDc1ODA0IiwiZXhwIjoxNzMzNzk4NDc1LCJ1c2VySWQiOiI1OGJmNzgxZTEzYTU0ODc2ODhlMTBmMDU2ZDI5YTk5ZCIsImFjY2Vzc1RpbWVzdGFtcCI6IjE3MDIyNjI0NzU4MDQifQ.hNaBa2rd4OOELjisa6vlufqOwKAW0vif1O2001NGsA4'
    // localStorage.setItem('oldToken', oldToken)

    // 本地开发的话打开下面这段注释  将里面的newToken和token 换成对应197的
    // let userInfo = {
    //   userName: '马超',
    //   companyName: '深圳优制云工业互联网有限公司',
    //   phone: '15013734208',
    //   userIcon: 'https://qiniu.veiban.com/D40F07E5435063F82BCB3E6B34D7496F.gif',
    //   tenantId: '1586979014478311425',
    //   newToken:
    //     'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2tleSI6ImU5YWVjNDAwLTJiMGItNDM1ZC1iNDQ1LTA4OTMyNDA4YjMwZCIsInVzZXJfaWQiOjE2MywidXNlcm5hbWUiOiIxNTAxMzczNDIwOCJ9.UIoNZ-YlGWQEW4yrmfXVB89Y4Ph9SXkmdSVTLyovXY2Nd3-9rRTeyBgGOHxshB1Yd1CmgAPRLDnydpQelSk5Vw',
    //   token:
    //     'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbktleSI6IlRISVJELTIxOGQxMDVmNGY2ODQwMWViMTkyNmI2ZjNkY2UwNjVjLWVkOGRjMzNkMzM0MTQ5OGM4MTU0NDRhZWVkNjlhNGFiIiwiZXhwaXJlVGltZXN0YW1wIjoiMTc1NTA1MDIyOTIwOCIsInBob25lIjoiMTUwMTM3MzQyMDgiLCJyZWZyZXNoVGltZXN0YW1wIjoiMTczOTI4MjIyOTIwOCIsImV4cCI6MTc1NTA1MDIyOSwidXNlcklkIjoiMjE4ZDEwNWY0ZjY4NDAxZWIxOTI2YjZmM2RjZTA2NWMiLCJhY2Nlc3NUaW1lc3RhbXAiOiIxNzIzNTE0MjI5MjA4In0.pRTRGzJwaP8WpHUKFar-m5nOKTX48yILpK2YhUvyPxs',
    //   companyId: 'cfe05dd2cedf4dd1ad9475d32838eaf9',
    //   officeId: '0a4ade7327a744d8893b146591a7f422',
    //   officeName: '研发部',
    //   userId: '218d105f4f68401eb1926b6f3dce065c',
    // };
    // localStorage.setItem('userInfo', JSON.stringify(userInfo));
    // let user = {
    //   id: "587", 
    //   code: "test01", 
    //   name: "马兴超", 
    //   tenantId: "1586979014478311425", 
    //   tenantName: "深圳优制云工业互联网有限公司", 
    //   userId: "163", 
    //   avatar: "https://qiniu.veiban.com/ACF2BA905548F8D95D7F7C2579EF5819.png", 
    //   phoneNumber: "15013734208", 
    //   deptId: "1018", 
    //   deptName: "研发部", 
    //   postId: "31", 
    //   postName: "软件测试"
    // };
    // localStorage.setItem('user', JSON.stringify(user));

    // 实际发布是下面这个
    let userInfo = await VeiBanBridge.getUserInfo()
      .then(res => {
        return res;
      })
      .catch(() => {});
    if (userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      if (userInfo.newToken) {
        this.getUserPostData();
      }
    }
  },
  methods: {
    getUserPostData() {
      getUserPost().then(res => {
        console.log('user===', res);
        if (res && res.code == 200) {
          let user = res.data;
          localStorage.setItem('user', JSON.stringify(user));
        }
      });
    }
  },
  destroyed() {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('user');
  }
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
