<template>
  <div class="container">
    <div class="container-header">
      <van-nav-bar left-arrow title="人员选择" safe-area-inset-top :border="false" @click-left="handleBack" />
      <div class="search">
        <van-search v-model="searchValue" @search="onSearch" show-action placeholder="请输入人员名搜索">
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </div>
    </div>
    <div class="list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <van-cell v-for="(item, index) in list" :key="index" class="item">
            <div class="item_line1">
              <van-checkbox v-model="item.isChecked" :disabled="getUnable(item)" @change="changeCheckBox(item)" shape="square"></van-checkbox>
              <div class="item_title">
                <img v-if="item.avatar" :src="item.avatar" />
                <img v-else src="@/assets/images/approval/default.png" />
                <div>
                  <div>{{ item.name }}</div>
                  <div>{{ item.phoneNumber }}</div>
                </div>
              </div>
            </div>
            <div class="item_line2">
              <div>
                <span>公司名称：</span>
                <span>{{ item.tenantName }}</span>
              </div>
              <div>
                <span>部门名称：</span>
                <span>{{ item.deptName }}</span>
              </div>
              <div>
                <span>岗位：</span>
                <span>{{ item.postName }}</span>
              </div>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="confirm_btn">
      <div class="select_num">已选{{ selectedList.hasOwnProperty(`${status}`) ? selectedList[`${status}`].length : 0 }}/100</div>
      <div @click="confirm" class="btn">确定</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { getStaffContacts } from '@/api/common';
import { Toast } from 'vant';
import { NavBar, Search, Icon, Button, List, PullRefresh, Checkbox, Cell } from 'vant';
export default {
  components: {
    [NavBar.name]: NavBar,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Checkbox.name]: Checkbox,
    [Cell.name]: Cell
  },
  data() {
    return {
      searchValue: '',
      loading: false,
      finished: false,
      refreshing: false,
      currentPage: 1,
      currentPageSize: 10,
      list: []
    };
  },
  computed: {
    ...mapState({
      selectedList: state => state.approval.selectedList,
      status: state => state.approval.status
    })
  },
  mounted() {
    console.log(this.selectedList);
  },
  methods: {
    ...mapMutations(['setSelectList']),
    handleBack() {
      this.$router.back();
    },
    onSearch() {
      this.currentPage = 1;
      this.list = [];
      this.onLoad();
    },
    getUnable(item) {
      const query = this.$route.query;
      let re = false;

      // 特殊处理汇报给我
      if (query?.type == 'to') {
        let to_copyIds = this.selectedList.to_copyIds || [];
        re = to_copyIds.some(i => i.staffId == item.staffId && i.id);
      }

      return re;
    },
    changeCheckBox(item) {
      console.log(item);
      this.setSelectList(item);
      this.$forceUpdate();
      this.$parent.$forceUpdate();
    },
    onLoad() {
      this.getStaffListData();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.currentPage = 1;
      this.onLoad();
    },
    confirm() {
      window.history.back();
    },
    getStaffListData() {
      const params = {
        size: this.currentPageSize,
        page: this.currentPage,
        param: {
          name: this.searchValue
        }
      };
      getStaffContacts(params).then(res => {
        if (res.code === 200) {
          this.currentPage++;
          this.loading = false;
          if (res.data) {
            let { records, total } = res.data;
            records.forEach(item => {
              item.staffId = item.id;
              if (this.selectedList[`${this.status}`]) {
                this.selectedList[`${this.status}`].forEach(i => {
                  if (item.staffId && item.staffId == i.staffId) {
                    item.isChecked = true;
                  }
                });
              }
              delete item.id;
            });
            if (this.refreshing) {
              this.list = [];
              this.refreshing = false;
            }
            if (this.list.length === 0) {
              this.list = records;
            } else {
              this.list = this.list.concat(records);
            }
            if (this.list.length >= total) {
              this.finished = true;
            } else {
              this.finished = false;
            }
          }
        } else {
          Toast.fail(res.msg);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  touch-action: none;
  height: 100vh;
  position: relative;
  padding-bottom: 1.81rem;
  box-sizing: border-box;
  background-color: #f9f9f9;
  .apply-tab {
    width: 100%;
    height: 64px;
    background: #ffffff;
    position: fixed;
    z-index: 99;
    padding-left: 10px;
    line-height: 64px;
    top: 0;
    left: 0;
    div {
      font-size: 0.48rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .list {
    width: 343px;
    margin: 16px auto;
    position: relative;
    flex: 1;
    overflow-y: auto;
    .item {
      width: 343px;
      height: 162px;
      background-color: #ffffff;
      border-radius: 4px;
      margin-bottom: 16px;
      padding: 16px;
      .item_line1 {
        display: flex;
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 15px;
        text-align: left;
        .item_title {
          display: flex;
          align-items: center;
          margin-left: 16px;
          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
          }
          div {
            font-family: Source Han Sans CN;
            color: #333333;
            font-size: 14px;
          }
        }
        .item_line2 {
          font-family: Source Han Sans CN;
          color: #666666;
          font-size: 14px;
        }
      }
      .item_line2 {
        text-align: left;
      }
    }
  }
  .confirm_btn {
    width: 100%;
    height: 1.81rem;
    background-color: #ffffff;
    box-shadow: 0px -2px 4px #efefef;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 99;
    box-sizing: border-box;
    .select_num {
      font-family: Source Han Sans CN;
      color: #1584ff;
      font-size: 14px;
      line-height: 14px;
    }
    .btn {
      width: 120px;
      height: 44px;
      background-color: #1584ff;
      border-radius: 8px;
      box-shadow: 0px 2px 8px #80bcff;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      font-size: 16px;
      line-height: 44px;
      text-align: center;
    }
  }
  .search {
    width: 100%;
    background-color: #ffffff;
  }
}
</style>
