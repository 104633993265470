const ua = window.navigator.userAgent
// 是否在伟班App中
const isInVeiban = () => {
  return ua.indexOf('veiban') > -1
}
const pageBack = () => {
  //
  if (isInVeiban()) {
    window.location.href = 'jstooc://back'
  } else {
    history.go(-1)
  }
}
export const device = {
  isAndroid: ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1,
  isIos: /(iPhone|iPad|iPod|iOS)/i.test(ua)
}
const registerFn = (fnName, fn) => {
  if (typeof fnName !== 'string') {
    throw TypeError('Illegal fnName: Not an string')
  }
  if (typeof fn !== 'function') {
    throw TypeError('ol. fn: Not an function')
  }
  window[fnName] = function (data) {
    fn(data)
  }
}

const getUserInfo = () => {
  console.log('getUserInfo:-----', isInVeiban(), device.isIos, ua)
  return new Promise((resolve, reject) => {
    registerFn('VBAPPUserInfo', function (data) {
      console.log('VBAPPUserInfo:', data)
      if (data) {
        // 成功则 resolve
        resolve(JSON.parse(data))
      } else {
        reject(data)
      }
    })
    if (isInVeiban()) {
      if (device.isAndroid) {
        const user = window.bridge.getUserData()
        resolve(JSON.parse(user))
      } else if (device.isIos) {
        console.log('isIos:', 11)
        window.webkit.messageHandlers.iosGetUserData.postMessage('data')
      }
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(null)
      return null
    }
  })
}

export default {
  isInVeiban,
  pageBack,
  device,
  getUserInfo
}
