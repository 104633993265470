import request from '@/utils/request'
import { METHOD, apiHost, oldVeiBanHost, uploadHost } from './config'

const { POST, GET } = METHOD

/**
 * 上传
 * @param {*} data
 * @returns
 */

export const fileUpLoad = data => {
  return request({
    url: `${uploadHost}/file/upload`,
    method: POST,
    timeout: 60000,
    data,
    "Content-Type": "multipart/form-data"
  })
}

/**
 * 获取用户列表
 */
export const getUserList = data => {
  return request({
    url: `${apiHost}/system/flow/assignment/users`,
    method: POST,
    data,
  })
}

// 模糊查询员工
export const getClassificationStatistics =  data => {
  return request({
    method: POST,
    url: `${oldVeiBanHost}/office/classificationStatistics`,
    data,
  })
}

// 查询员工(新平台)
export const getStaffContacts =  data => {
  return request({
    method: POST,
    url: `${uploadHost}/system/staff/contacts`,
    data,
  })
}

// 查询员工岗位信息
export const getUserPost =  () => {
  return request({
    method: GET,
    url: `${uploadHost}/system/staff/v1/current`,
  })
}

