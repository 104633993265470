const state = {
  layout: 'lg'
}

const mutations = {
  SET_LATOUT: (state, layout) => {
    state.layout = layout
  }
}

const actions = {
  toggleLoyout({ commit }, val) {
    commit('SET_LATOUT', val)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
