import BigNumber from 'bignumber.js'
const ua = navigator.userAgent.toLowerCase()
// 是否在微信内部
const isWeixin = ua.indexOf('micromessenger') !== -1
// 是否在app内部
const isApp = /(^|;\s)app\//.test(ua)

function isIOS() {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

function isAndroid() {
  if (/(Android|Adr)/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}
function getUrlKey(name) {
  // eslint-disable-next-line
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}
// 日期格式化
function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      // 加了反而不兼容火狐
      // time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

function formatProportion(v) {
  if (!v || v === '-') {
    return v
  }
  return BigNumber(v).toFixed(2) + '%'
}

function moneyFormat(value) {
  if (value !== 0 && !value) {
    return '-'
  }
  const fmt = {
    prefix: '',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    secondaryGroupSize: 3,
  }
  return BigNumber(value).toFormat(2, fmt)
}

// 手势封装
const handlerSwiper = (domClass, cb) => {
  const dom = document.querySelector(domClass)

  var startx, starty;
  //获得角度
  function getAngle(angx, angy) {
    return Math.atan2(angy, angx) * 180 / Math.PI;
  };

  //根据起点终点返回方向 1向上滑动 2向下滑动 3向左滑动 4向右滑动 0点击事件
  function getDirection(startx, starty, endx, endy) {
    var angx = endx - startx;
    var angy = endy - starty;
    var result = 0;

    //如果滑动距离太短
    if (Math.abs(angx) < 2 && Math.abs(angy) < 2) {
      return result;
    }

    var angle = getAngle(angx, angy);
    if (angle >= -135 && angle <= -45) {
      result = 1;
    } else if (angle > 45 && angle < 135) {
      result = 2;
    } else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
      result = 3;
    } else if (angle >= -45 && angle <= 45) {
      result = 4;
    }
    return result;
  }

  //手指接触屏幕
  //  let box = document.querySelector('#box')
  dom.addEventListener("touchstart", function (e) {
    startx = e.touches[0].pageX;
    starty = e.touches[0].pageY;
  }, false);

  //手指离开屏幕
  dom.addEventListener("touchend", function (e) {
    var endx, endy;
    endx = e.changedTouches[0].pageX;
    endy = e.changedTouches[0].pageY;
    var direction = getDirection(startx, starty, endx, endy);
    // 0点击  1向上 2向下 3向左 4向右
    // 各个方向操作逻辑写在回调函数内
    cb(direction)
  }, false);
}

// 使用方式：
// 0点击  1向上 2向下 3向左 4向右

// function doExample(n) {
//     // console.log(n);
//     if (n === 0) {
//         console.log('点击');
//     }
//     if (n === 1) {
//         console.log('向上');
//     }
//     if (n === 2) {
//         console.log('向下');
//     }
//     if (n === 3) {
//         console.log('向左');
//     }
//     if (n === 4) {
//         console.log('向右');
//     }
// }
// handlerSwiper('#box', doExample)


export { isApp, isWeixin, isIOS, isAndroid, getUrlKey, parseTime, formatProportion, moneyFormat, handlerSwiper }
