<template>
  <div class="selectCopy-container">
    <van-nav-bar
      left-arrow
      :title="title"
      safe-area-inset-top
      :border="false"
      @click-left="onClickLeft"
    />
    <div class="search-member">
      <van-search v-model="value" placeholder="搜索" @search="search" />
    </div>
    <div class="member-content">
      <div class="member-box" v-show="visible">
        <div v-for="(item, index) in memberList" :key="index" class="item-list" @click="handleClickMember(item)">
          <div class="item-list-box">
            <div class="clickbox">
              <img v-if="item.check" class="clickbox-img" src="@/assets/images/approval/blank.png" />
              <img v-else class="clickbox-img" src="@/assets/images/approval/Check.png" />
            </div>
            <div class="concentbox">
              <div class="concentbox-top">
                <div style="padding-top: 0.4rem">
                  <img v-if="item.avatar" class="concentbox-img" :src="item.avatar" />
                  <img v-else class="concentbox-img" src="@/assets/images/approval/default.png" />
                </div>
                <div style="padding-top: 0.2rem;display: flex;align-items: center;" >
                  <p>{{ item.nickname }}</p>
                </div>
              </div>
              <!-- <div>
                <div class="title">公司名称：{{ item.companyName }}</div>
                <div class="title">部门名称：{{ item.officeName }}</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="member-box" v-show="!visible">
        <div v-for="(item, index) in searchList" :key="index" class="item-list" @click="handleClick(item)">
          <div class="item-list-box">
            <div class="clickbox">
              <img v-if="item.check" class="clickbox-img" src="@/assets/images/approval/blank.png" />
              <img v-else class="clickbox-img" src="@/assets/images/approval/Check.png" />
            </div>
            <div class="concentbox">
              <div class="concentbox-top">
                <div style="padding-top: 0.4rem">
                  <img v-if="item.avatar" class="concentbox-img" :src="item.avatar" />
                  <img v-else class="concentbox-img" src="@/assets/images/approval/default.png" />
                </div>
                <div style="padding-top: 0.2rem;display: flex;align-items: center;" >
                  <p>{{ item.nickname }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <span style="color: #54B2ED; padding-left: .4rem">已选{{ selectedList.hasOwnProperty(`${status}`) ? selectedList[`${status}`].length : 0 }}/100</span>
      <van-button type="primary" style="margin-right: .4rem" @click="onConfirm">确定</van-button>
    </div>
  </div>
</template>
<script>
import {
  getUserList,
} from '@/api/common'
import { mapState } from "vuex";
import { NavBar, Search,Icon, Button } from 'vant'
export default {
  components: {
    [NavBar.name]: NavBar,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Button.name]: Button,
  },
  data() {
    return {
      title: "人员选择",
      value: "",
      memberList: [],
      autoExpandParent: true,
      checkedKeys: [],
      visible: true,
      searchList: [],
    };
  },
  watch: {
    value(val) {
      if (val === "") {
        this.visible = true;
      }
    },
  },
  computed: {
    ...mapState({
      selectedList: state => state.approval.selectedList,
      status: state => state.approval.status
    }),
  },
  mounted() {
    this.getMemeberList();
  },
  methods: {
    // 点击选择
    handleClickMember(item) {
      this.memberList.map((i) => {
        if (i.staffId === item.staffId) {
          i.check = !i.check;
          this.checkedKeys = i.check === true ? this.checkedKeys.filter((item) => item !== i.staffId) : (this.checkedKeys || []).concat([item.staffId]);
          this.$store.commit("setCheckedList", {
            data: item,
            status: i.check,
            number: this.status,
          });
        }
      });
    },
    // 搜索点击
    handleClick(item) {
      this.searchList.map((i) => {
        if (i.staffId === item.staffId) {
          i.check = !i.check;
          this.checkedKeys = i.check === true ? this.checkedKeys.filter((item) => item !== i.staffId) : (this.checkedKeys || []).concat([item.staffId]);
          this.$store.commit("setCheckedList", {
            data: item,
            status: i.check,
            number: this.status,
          });
        }
      });
    },
    // 获取用户列表
    getMemeberList() {
      getUserList({
        page: 1,
        size: 999,
        param: {
          nickname: this.value
        }
      }).then((res) => {
        if (res && res.code === 200) {
          let data = res.data.records.map((item) => ({ ...item, check: true, staffId: item.id, staffName: item.nickname }));
          if (this.selectedList[`${this.status}`]) {
            this.checkedKeys = this.selectedList[`${this.status}`].map((item) => item.staffId);
            data.map((item) => {
              if (this.checkedKeys.includes(item.staffId)) {
                item.check = false;
              }
            });
          }
          this.memberList = data
        }
      });
    },
    // 导航栏返回
    onClickLeft() {
      window.history.back();
    },
    // 搜素
    search() {
      if (!this.value) return;
      this.visible = false;
      const params = {
        page: 1,
        size: 999,
        param: {
          nickname: this.value
        }
      };
      getUserList(params).then((res) => {
        if (res && res.code === 200) {
          let data = res.data.records.map((item) => ({ ...item, check: true, staffId: item.id, staffName: item.nickname }));
          if (this.selectedList[`${this.status}`]) {
            this.checkedKeys = this.selectedList[`${this.status}`].map((item) => item.staffId);
            data.map((item) => {
              if (this.checkedKeys.includes(item.staffId)) {
                item.check = false;
              }
            });
          }
          this.searchList = data;
        }
      });
    },
    onConfirm() {
      window.history.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.selectCopy-container {
  min-height: 100%;
  overflow: auto;
  .van-nav-bar {
    position: fixed;
    width: 100%;
  }
  .app-header {
    position: fixed;
  }
  .search-member {
    position: fixed;
    top: 1.26rem;
    width: 100%;
    z-index: 99;
  }
  .member-content {
    padding-top: 2.7rem;
    padding-bottom: 10vh;
    position: relative;
    overflow: auto;
  }
}
.member-max {
  height: 0.45rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.55rem;
  margin-left: 0.37rem;
}
.member-box {
  /* width: 100vw;
    font-size: 16px; */
  width: 92%;
  margin: 0.4rem;
  border-radius: 8px;
  background: white;
  font-size: 16px;
  padding: 0.43rem;
  box-sizing: border-box;
}
.search-list {
  width: 90vw;
  /* background: white; */
  min-height: 40vh;
  margin: 0.2rem auto;
  /* padding-left: 0.2rem; */
}
.item-list {
  display: flex;
  align-items: center;
  margin-bottom: 0.1rem;
  border-bottom: 1px solid #F3F3F3;
  .item-list-box {
    display: flex;
    width: 90vw;
    // height: 128px;
    height: 60px;
    background: #FFFFFF;
    border-radius: 8px;
    .clickbox-img {
      width: .4rem;
      height: .4rem;
    }
  }
}
.concentbox-top {
  display: flex;
  // border-bottom: 1px solid #F3F3F3;
  min-height: 7vh;
  .concentbox-img {
    margin-left: .2rem;
    margin-right: .2rem;
    width: .8rem;
    height: .8rem;
  }
}
.footer {
  width: 100vw;
  height: 10vh;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -2px 4px 0px #efefef;
  font-size: 16px;
}
.clickbox {
  width: 1rem;
  padding: 0.3rem;
  margin-right: 0.2rem;
  padding-top: 0.5rem;
}
.concentbox {
  // width: 89vw;
  // border-bottom: 1px solid #F3F3F3;
}
p {
  margin-bottom: 0 !important;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /* margin-top: 0.1rem; */
}
.title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 0.15rem;
}
</style>
