const state = {
  selectedList: {},
  status: '',
  keepAlive: []
}

const mutations = {
  setSelectedList(state, selectedList) {
    const arr = [...selectedList]
    state.selectedList[`${state.status}`] = arr;
  },
  setSelectList(state, params) {
    if(!state.selectedList[`${state.status}`]) {
      state.selectedList[`${state.status}`] = [params]
    }else {
      state.selectedList[`${state.status}`] = params.isChecked ? [...state.selectedList[`${state.status}`], params] :
      state.selectedList[`${state.status}`].filter(item => item.staffId != params.staffId)
    }
  },
  setCheckedList(state, params) {
    if(params.status) {
      state.selectedList[`${params.number}`] = state.selectedList[`${params.number}`].filter(item => item.staffId != params.data.staffId)
    }else {
      state.selectedList[`${params.number}`] = state.selectedList[`${params.number}`] ?
      [...state.selectedList[`${params.number}`], params.data] : [params.data]
    }
  },
  setStatus(state, status) {
    state.status = status;
  },
  SET_KEEP_ALIVE(state, params) {
    state.keepAlive = params;
  },
  clearSelectedList(state, selectedList) {
    state.selectedList = selectedList;
  },
}

export default {
  state,
  mutations
}
